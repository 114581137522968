import { gql } from "graphql-request";
import { UseIssuesFieldsFragment } from "../useIssues/fields";

export const UseCustomersFieldsFragment = gql`
  fragment UseCustomersFields on Customer {
    id
    name
    language
    image {
      id
      originPath
      mediaWidth
      mediaHeight
      allowCropping
      showOriginalSize
    }
    template {
      id
      name
      updatedAt
    }
    templateId
    defaultIssueId
    defaultIssue {
      ...UseIssuesFields
    }
    executiveBriefingTopics {
      id
      title
      subTitle
      showStoryOnly
    }
    inboxEbsId
    inboxAddcovId
    inboxSocialMediaId
    showLicensedContent
    contactListIds
    hasCustomFooter
  }
  ${UseIssuesFieldsFragment}
`;
